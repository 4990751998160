import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Direct_LAN_Connection/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Direct LAN Connection",
  "path": "/Quick_Installation/Direct_LAN_Connection/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Directly connect your Camera to your Computer",
  "image": "./QI_SearchThumb_DirectConnectionWithYourPC.png",
  "social": "/images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Direct_Connection_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Direct LAN Connection' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Directly connect your Camera to your Computer' image='/images/Search/QI_SearchThumb_DirectConnectionWithYourPC.png' twitter='/images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Direkte_LAN_Verbindung/' locationFR='/fr/Quick_Installation/Direct_LAN_Connection/' crumbLabel="Direct LAN" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "direct-lan-connection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#direct-lan-connection",
        "aria-label": "direct lan connection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Direct LAN Connection`}</h1>
    <h3 {...{
      "id": "how-to-connect-your-camera-directly-with-your-computer-via-an-ethernet-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-connect-your-camera-directly-with-your-computer-via-an-ethernet-cable",
        "aria-label": "how to connect your camera directly with your computer via an ethernet cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to connect your camera directly with your computer via an Ethernet cable?`}</h3>
    <p>{`The following instructions will explain to you step by step how you can create a direct LAN connection between your computer and an INSTAR IP camera. Use this approach if you want to use the camera in an area, that does not provide a network infrastructure or to if you want to configure the camera to connect to a WiFi network when you don´t have a LAN Ethernet connection available to make the initial WiFi configuration.`}</p>
    <h2 {...{
      "id": "connect-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connect-the-camera",
        "aria-label": "connect the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect the Camera`}</h2>
    <p>{`Connect the camera with a regular Ethernet cable to your PC or Notebook (Very old PCs might still need a special - `}<Link to="http://en.wikipedia.org/wiki/Ethernet_crossover_cable" target="_blank" rel="noopener noreferrer" mdxType="Link">{`Crossover`}</Link>{` - cable to make this connections).`}</p>
    <h2 {...{
      "id": "search-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#search-the-camera",
        "aria-label": "search the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Search the Camera`}</h2>
    <p>{`Install/Open the INSTAR Camera Tool which will show you the following message after clicking the `}<em parentName="p">{`Search`}</em>{` button: (you might have to select the correct network interface to which the camera is connected to. If for example you have a VPN connection, an active Bluetooth module and the regular LAN interface, then you have to select the LAN interface in order to find your IP camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a198627fcaa06123ae9f0e9931f1569/b4294/Direktverbindung_01en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEFBP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdKpMklUP//EABkQAAIDAQAAAAAAAAAAAAAAAAACAQQSEf/aAAgBAQABBQJqrtM1GJqOaNHT/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMxETIP/aAAgBAQAGPwLOxotkXPj/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhUdHx/9oACAEBAAE/IYBkkX0jwHphnH6J+IqGj//aAAwDAQACAAMAAAAQg8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMWFRgdH/2gAIAQEAAT8QIkAU0vsYUr7+xbQgoydfKBE0QS1EXVQn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a198627fcaa06123ae9f0e9931f1569/e4706/Direktverbindung_01en.avif 230w", "/en/static/0a198627fcaa06123ae9f0e9931f1569/d1af7/Direktverbindung_01en.avif 460w", "/en/static/0a198627fcaa06123ae9f0e9931f1569/56a33/Direktverbindung_01en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a198627fcaa06123ae9f0e9931f1569/a0b58/Direktverbindung_01en.webp 230w", "/en/static/0a198627fcaa06123ae9f0e9931f1569/bc10c/Direktverbindung_01en.webp 460w", "/en/static/0a198627fcaa06123ae9f0e9931f1569/e88ff/Direktverbindung_01en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a198627fcaa06123ae9f0e9931f1569/e83b4/Direktverbindung_01en.jpg 230w", "/en/static/0a198627fcaa06123ae9f0e9931f1569/e41a8/Direktverbindung_01en.jpg 460w", "/en/static/0a198627fcaa06123ae9f0e9931f1569/b4294/Direktverbindung_01en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a198627fcaa06123ae9f0e9931f1569/b4294/Direktverbindung_01en.jpg",
              "alt": "Instar Camera Tool",
              "title": "Instar Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the camera tool and press `}<strong parentName="p">{`Search`}</strong>{`.`}</p>
    <h2 {...{
      "id": "assign-a-static-ip-to-your-windows-pc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assign-a-static-ip-to-your-windows-pc",
        "aria-label": "assign a static ip to your windows pc permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assign a static IP to your Windows PC`}</h2>
    <p>{`Open the Network and Sharing Centre (Control Panel -> Network and Security). The interface will show you a new unidentified LAN connection. Open it´s settings by clicking `}<em parentName="p">{`Local Area Network`}</em>{`. Go to Properties, select the Internet Protocol Version 4 (TCP/IPv4) and select Properties as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0cfd8a3ef58b780d68903197fbe914ca/29d31/Direktverbindung_02en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHrbmkrJbsSBf/EABgQAAMBAQAAAAAAAAAAAAAAAAABESEQ/9oACAEBAAEFAsqRO6IQz//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABUQAQEAAAAAAAAAAAAAAAAAABEw/9oACAEBAAY/AiP/xAAfEAACAQMFAQAAAAAAAAAAAAABEQAhMVEQQXGhwdH/2gAIAQEAAT8hBtADxBM0CcTAiofsVPBHh1N0AK2j/9oADAMBAAIAAwAAABDs6P7/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAhP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGM//8QAHRABAAICAgMAAAAAAAAAAAAAAQARIUExUWHB0f/aAAgBAQABPxC4VxRdvUFYAWscxubTrEbtVmyBlxa8SEQ+oJrNA56gpxP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cfd8a3ef58b780d68903197fbe914ca/e4706/Direktverbindung_02en.avif 230w", "/en/static/0cfd8a3ef58b780d68903197fbe914ca/d1af7/Direktverbindung_02en.avif 460w", "/en/static/0cfd8a3ef58b780d68903197fbe914ca/70e80/Direktverbindung_02en.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0cfd8a3ef58b780d68903197fbe914ca/a0b58/Direktverbindung_02en.webp 230w", "/en/static/0cfd8a3ef58b780d68903197fbe914ca/bc10c/Direktverbindung_02en.webp 460w", "/en/static/0cfd8a3ef58b780d68903197fbe914ca/426ac/Direktverbindung_02en.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cfd8a3ef58b780d68903197fbe914ca/e83b4/Direktverbindung_02en.jpg 230w", "/en/static/0cfd8a3ef58b780d68903197fbe914ca/e41a8/Direktverbindung_02en.jpg 460w", "/en/static/0cfd8a3ef58b780d68903197fbe914ca/29d31/Direktverbindung_02en.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0cfd8a3ef58b780d68903197fbe914ca/29d31/Direktverbindung_02en.jpg",
              "alt": "Windows Network Configuration",
              "title": "Windows Network Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the Windows Network- and Sharing Centre and go to the LAN adapter properties.`}</p>
    <p>{`The following window will open and allow you to set a static IP address for your PC/Laptop. Submit the settings by clicking `}<strong parentName="p">{`OK`}</strong>{` and return to the IP Camera Tool.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22df937223017abaf4f09ef14a3b0d0f/066f9/Direktverbindung_03en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6l9JTNcqCyAf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECEBIR/9oACAEBAAEFAsigYVumzp//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEgQf/aAAgBAQAGPwLhERb/AP/EAB0QAAIBBAMAAAAAAAAAAAAAAAABESExQVFhgaH/2gAIAQEAAT8haaCIoLyzEpY8L8FxInsrP//aAAwDAQACAAMAAAAQzOcB/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EMIuE//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QrxIh/8QAHhABAAIBBAMAAAAAAAAAAAAAAQARITFBYXFRgeH/2gAIAQEAAT8QO4L38iAq5zBpzFEKlpxyljxdPJU0uoYHUIFlrR2m66mME//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22df937223017abaf4f09ef14a3b0d0f/e4706/Direktverbindung_03en.avif 230w", "/en/static/22df937223017abaf4f09ef14a3b0d0f/af71e/Direktverbindung_03en.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22df937223017abaf4f09ef14a3b0d0f/a0b58/Direktverbindung_03en.webp 230w", "/en/static/22df937223017abaf4f09ef14a3b0d0f/7f61c/Direktverbindung_03en.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22df937223017abaf4f09ef14a3b0d0f/e83b4/Direktverbindung_03en.jpg 230w", "/en/static/22df937223017abaf4f09ef14a3b0d0f/066f9/Direktverbindung_03en.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22df937223017abaf4f09ef14a3b0d0f/066f9/Direktverbindung_03en.jpg",
              "alt": "Windows Network Configuration",
              "title": "Windows Network Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the IPv4 properties.`}</p>
    <p>{`Please fill in a valid IP address for your computer and set it to be new networks gateway and DNS server (see below):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7e9bdeca761a338763cfba74b6434a7/066f9/Direktverbindung_04en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHrVWpCxOlQD//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhAREkH/2gAIAQEAAQUCw5HFV66cjT//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEgQf/aAAgBAQAGPwKI4Rb/AP/EABsQAAICAwEAAAAAAAAAAAAAAAABETEQIZFB/9oACAEBAAE/IYoUsSlyJ3wE4fpe0ovjRBtZ/9oADAMBAAIAAwAAABBcODz/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EIicX//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxDWBP/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExYXFBUYH/2gAIAQEAAT8QVY+g3GgK+ZECwpmoA0soFVxGrLAaZr6lmG0eiIAGXzL+xMT/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7e9bdeca761a338763cfba74b6434a7/e4706/Direktverbindung_04en.avif 230w", "/en/static/a7e9bdeca761a338763cfba74b6434a7/af71e/Direktverbindung_04en.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7e9bdeca761a338763cfba74b6434a7/a0b58/Direktverbindung_04en.webp 230w", "/en/static/a7e9bdeca761a338763cfba74b6434a7/7f61c/Direktverbindung_04en.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7e9bdeca761a338763cfba74b6434a7/e83b4/Direktverbindung_04en.jpg 230w", "/en/static/a7e9bdeca761a338763cfba74b6434a7/066f9/Direktverbindung_04en.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7e9bdeca761a338763cfba74b6434a7/066f9/Direktverbindung_04en.jpg",
              "alt": "Windows Network Configuration",
              "title": "Windows Network Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Assign a static IP address to your PC.`}</p>
    <h2 {...{
      "id": "assign-a-static-ip-to-your-mac",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assign-a-static-ip-to-your-mac",
        "aria-label": "assign a static ip to your mac permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assign a static IP to your Mac`}</h2>
    <p>{`Open the menu System Preferences go to Network and click on Ethernet. Please choose Configure IPv4: Manually and type in a valid IP address. Use the same IP address as the Router IP. Submit the new settings with Apply and return to the IP Camera Tool.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/b4294/Direktverbindung_MAC_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHrTZiUChQf/8QAFRABAQAAAAAAAAAAAAAAAAAAETD/2gAIAQEAAQUCI//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABwQAAICAgMAAAAAAAAAAAAAAAABEBEhQWFx0f/aAAgBAQABPyHqqEleEVwa0JVPkf/aAAwDAQACAAMAAAAQ8MAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAAMAAwEBAAAAAAAAAAAAAQARITFBUXGR/9oACAEBAAE/ELaohrFuIAKe2ynevsXYNLgbOfZd7v5GPJB3P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/e4706/Direktverbindung_MAC_en.avif 230w", "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/d1af7/Direktverbindung_MAC_en.avif 460w", "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/56a33/Direktverbindung_MAC_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/a0b58/Direktverbindung_MAC_en.webp 230w", "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/bc10c/Direktverbindung_MAC_en.webp 460w", "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/e88ff/Direktverbindung_MAC_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/e83b4/Direktverbindung_MAC_en.jpg 230w", "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/e41a8/Direktverbindung_MAC_en.jpg 460w", "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/b4294/Direktverbindung_MAC_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5bd7db4c96e5a2d2c76d648c3cc830c8/b4294/Direktverbindung_MAC_en.jpg",
              "alt": "macOS Network Configuration",
              "title": "macOS Network Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to the System Preferences and open the Ethernet configuration.`}</p>
    <h2 {...{
      "id": "assign-a-static-ip-to-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assign-a-static-ip-to-your-camera",
        "aria-label": "assign a static ip to your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assign a static IP to your Camera`}</h2>
    <p>{`Click on `}<em parentName="p">{`IP Config`}</em>{` and select your camera on the left side. Fill in the IP address for the camera. The address has to be in the same IP subnet you used for your computer in `}<strong parentName="p">{`step 3`}</strong>{` but has to be different from the address you choose above - e.g. `}<strong parentName="p">{`just add 1`}</strong>{` to the last octet (see example below)! The Gateway and DNS server will be the IP address of your computer that you set above.`}</p>
    <p>{`If you are using a class C network the standard subnet mask is (Subnet Mask: 255.255.255.0).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/460fb364066de14dbcc8a9053f4e063c/b4294/Direktverbindung_05en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFBP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdKVkJRVD//EABkQAAMBAQEAAAAAAAAAAAAAAAABAgQRIf/aAAgBAQABBQKs1U3lY8tM6P1n/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMxETIP/aAAgBAQAGPwLOxotkXPj/xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhEDFRcdHh/9oACAEBAAE/IaaSSA9gNKMTUVse5xmox//aAAwDAQACAAMAAAAQAw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMWFBgdH/2gAIAQEAAT8QGkAUiw5xa9QfuVhGDWzvBIHh3kItUkyaz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/460fb364066de14dbcc8a9053f4e063c/e4706/Direktverbindung_05en.avif 230w", "/en/static/460fb364066de14dbcc8a9053f4e063c/d1af7/Direktverbindung_05en.avif 460w", "/en/static/460fb364066de14dbcc8a9053f4e063c/56a33/Direktverbindung_05en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/460fb364066de14dbcc8a9053f4e063c/a0b58/Direktverbindung_05en.webp 230w", "/en/static/460fb364066de14dbcc8a9053f4e063c/bc10c/Direktverbindung_05en.webp 460w", "/en/static/460fb364066de14dbcc8a9053f4e063c/e88ff/Direktverbindung_05en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/460fb364066de14dbcc8a9053f4e063c/e83b4/Direktverbindung_05en.jpg 230w", "/en/static/460fb364066de14dbcc8a9053f4e063c/e41a8/Direktverbindung_05en.jpg 460w", "/en/static/460fb364066de14dbcc8a9053f4e063c/b4294/Direktverbindung_05en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/460fb364066de14dbcc8a9053f4e063c/b4294/Direktverbindung_05en.jpg",
              "alt": "Instar Camera Tool",
              "title": "Instar Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the IP configuration tab of the INSTAR camera tool and set a valid IP address and submit.`}</p>
    <p>{`After submitting the data, the camera will restart which takes about 30 seconds. Click on Search to find the camera again and double-click die camera entry to open the WebUI inside your default browser. If you want to use a different browser, type in the camera´s IP address into your browser´s address line and press Return. You are now able to change the camera´s settings and access the live stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/b4294/Direktverbindung_06en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHdVGkgrC//xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIEERIU/9oACAEBAAEFAqx7b8tDxbZ0dGz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAAQIREiAyM//aAAgBAQAGPwKt8jeZ1aw//8QAHBAAAgICAwAAAAAAAAAAAAAAAAERIVHhkcHR/9oACAEBAAE/IY2VUQlscNcWzpE/RPhEix//2gAMAwEAAgADAAAAEJ/v/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EKF//8QAGhABAQEBAAMAAAAAAAAAAAAAAREAMUFRYf/aAAgBAQABPxAWBBTBnnHQd98Y8cxIFfuwThiAwy5UN//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/e4706/Direktverbindung_06en.avif 230w", "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/d1af7/Direktverbindung_06en.avif 460w", "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/56a33/Direktverbindung_06en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/a0b58/Direktverbindung_06en.webp 230w", "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/bc10c/Direktverbindung_06en.webp 460w", "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/e88ff/Direktverbindung_06en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/e83b4/Direktverbindung_06en.jpg 230w", "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/e41a8/Direktverbindung_06en.jpg 460w", "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/b4294/Direktverbindung_06en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f3ce2a89b7cb82b41cc036ab5e8cc6a8/b4294/Direktverbindung_06en.jpg",
              "alt": "Instar Camera Tool",
              "title": "Instar Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the camera´s new IP address with your default browser.`}</p>
    <p>{`If you choose the direct connection to do the initial WLAN configuration of your camera to integrate it into an existing WiFi network, type in the WLAN information under `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/WiFi/"
      }}>{`Network/WIFI`}</a>{` and change the cameras IP address to a free address in the IP subnet that is used inside the wireless network. The camera will then restart - please unplug the LAN cable right after submitting the new IP address to allow the camera to connect to your wireless LAN.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      